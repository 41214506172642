import React from "react";
import {
  SEO,
  PageLayout,
  About,
  DaySmartAppointmentBooking,
} from "@bluefin/components";
import { Grid, Divider } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class BookNowPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <About
                content={
                  "Clients can book an appointment online quickly and easily! With just a few steps, clients can pick the date and time they'd like to come in, and schedule multiple services with the first available employee or a selected employee of their choice."
                }
                header={"Book an Appointment Online"}
                centerContent={true}
                headerAs={"h1"}
                withContainer={true}
              />
              <Divider hidden={true} />
              <DaySmartAppointmentBooking
                guid={"afa10b7d-5843-49b9-b44c-262bdcaeb053"}
                title={"Book Appointment Now"}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Book Now" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
